const featuredProjectData = [
  {
    key: 'focalpoint',
    title: 'Focal Point',
    description: 'Call management application',
    story:
      'We worked with IBM to build a new version of their global call management application. The application was built with Angular with Twitter Bootstrap CSS Framework. We created custom Angular application validation and form components to increase productivity and consisitency within the workflow.',
    featureList: ['Angular', 'Twitter Bootstrap', 'Javascript', 'Grunt', 'Sass', 'AngularUI'],
    galleryCount: 12,
    id: 10,
  },
  {
    key: 'ofgsupply',
    title: 'OFG Supply Website',
    description: 'Inventory management system',
    story:
      '<p>We built a custom tool for Ontario Flower Growers to work with Lowe’s Home Improvement manage inventory of product. Users can login to view inventory and availability. Leave comments and edit comments.</p><p>The application also used ODBC to sync data from the website and the internal company AS400 IBM database system.</p><p>The admin tool allows uploading inventory data to be imported into the database using csv or xml format.</p><p>Administrators can also manage inventory for all stores, create new stores and manage users.</p>',
    featureList: ['PHP', 'MySQL', 'Codeigniter', 'JQuery', 'Twitter Bootstrap'],
    galleryCount: 3,
    id: 9,
  },
  {
    key: 'virtualclothesline',
    title: 'Canadian Diabetes Association',
    description: 'Charity Website',
    story:
      '<p>VirtualClothesline.ca was a site we built for The Data Group.</p><p>The site was a charity site for Canadian Diabetes Association. Users were able to make virtual clothing donations.</p><p>The site also had social media integration where users could login and share their donations to their Facebook wall or send a tweet promoting their donation to Twitter.</p><p>The site was designed with PHP Codeigniter framework and Adobe Flash.</p>',
    featureList: ['PHP', 'Codeigniter', 'MySQL', 'Flash', 'JQuery', 'Twitter Bootstrap'],
    galleryCount: 3,
    id: 8,
  },
  {
    key: 'harlequin',
    title: 'Harlequin Website',
    description: 'Anniversary Website',
    story:
      '<p>Tryharlequin.com was a site we built for M Marketing Inc. The site offered free book downloads to users to help celebrate Harequin&apos;s anniversary.</p><p>The site also included an administrator dashboard that showed user statistics like how many ebooks were downloaded, which genre&apos;s were the most popular and more usual site usage information.</p><p>The site was designed with PHP Codeigniter framework and Adobe Flash.</p>',
    featureList: ['PHP', 'Codeigniter', 'MySQL', 'Flash', 'JQuery', 'Twitter Bootstrap'],
    galleryCount: 0,
    id: 7,
  },
  {
    key: 'produceplace',
    title: 'Produce Place Website',
    description: 'Website &amp; Admin tool',
    story:
      '<p>A custom designed website and content management system (CMS).</p><p>The site provided up-to-date information to the food service industry on the availability of locally grown fruits and vegetables.</p><p>The admin portal allows editing growers, news content and other admin functions to help the client manage the content of the site.</p><p>The project was built with PHP, Codeigniter and MySQL.</p><p>The site was also multilingual for English and French languages.</p>',
    featureList: ['PHP', 'Codeigniter', 'MySQL', 'Flash', 'JQuery', 'Twitter Bootstrap'],
    galleryCount: 4,
    id: 6,
  },
  {
    key: 'moneris',
    title: 'Moneris Offer Website',
    description: 'Responsive Website',
    story:
      '<p>The project was built for M Marketing Inc for their client Moneris. It&amp;s an offer site to help users sign up for their business promotions.</p><p>The site has a responsive design for mobile devices and desktop and is available in English and French.</p><p>It was developed using PHP, Codeigniter, MySQL, Jquery and Twitter Bootstrap framework.</p>',
    featureList: ['PHP', 'Codeigniter', 'MySQL', 'Flash', 'JQuery', 'Twitter Bootstrap'],
    galleryCount: 3,
    id: 5,
  },
  {
    key: 'ofg',
    title: 'OFG Website',
    description: 'Multiple portals &amp; ordering sytem',
    story:
      '<p>Website and content management system (CMS) for Ontario Flower Growers. The site had multiple portals for buyers, growers and administrators.</p><p>The website allowed growers in Ontario to place their products and product availability online. Registered buyers could login to the system and purchase grower products and choose which days they would like to pick up their products.</p><p>An application was also created to sync data from the website and the internal company AS400 IBM database system.</p><p>The site was made with ASP.net 2, Jquery, Ms SQL and Macromedia Flash.</p>',
    featureList: ['ASP.net', 'Microsoft SQL Server', 'JQuery', 'Twitter Bootstrap'],
    galleryCount: 11,
    id: 4,
  },
  {
    key: 'innocean',
    title: 'Innocean Extranet',
    description: 'File sharing &amp; upload tool',
    story:
      '<p>We built an extranet web application for Innocean Canada. The application has an administration tool to manage users, files and permissions. New clients and companies can be added easily with the tool.</p><p>Users of the tool can upload and track files with the ability to access previous file versions with the custom upload tool we built. Files are easily uploaded right from the browser.</p><p>The project was built with PHP, MySQL and the Codeigniter and Twitter Bootstrap framework.</p>',
    featureList: ['Laravel', 'PHP', 'JQuery', 'Bootstrap'],
    galleryCount: 4,
    id: 3,
  },
  {
    key: 'smart',
    title: 'Flight Centre CMS',
    description: 'Social CMS tool',
    story:
      '<p>We developed a content manage system (CMS) for Flight Centre Travel Group to manage their global social media campaigns. The application was developed using Laravel PHP framework, Amazon SDK, Angular and Twitter Bootstrap framework.</p><p>The application allows employees to create new campaigns, manage users, security settings, upload and manage files and create pages for the application.</p><p>Users are able to create Facebook pages using the application using a WYSIWYG tool for content creation. Users with no programming experience can modify and edit pages, but a visual source code editor still allows advanced users the ability to edit code directly</p>',
    featureList: ['Angular', 'Laravel', 'PHP', 'Bootstrap'],
    video: 'https://www.youtube.com/embed/CnN8iMoJH-A',
    galleryCount: 6,
    id: 2,
  },
  {
    key: 'vpg',
    title: 'Virtually Perfect Golf App',
    description: 'VR golf training system',
    story:
      'We worked with IBM to build a new version of their global call management application. The application was built with Angular with Twitter Bootstrap CSS Framework. We created custom Angular application validation and form components to increase productivity and consisitency within the workflow.',
    featureList: ['XML', 'Macromedia Director', 'Actionscript', 'Macromedia Flash'],
    video: 'https://www.youtube.com/embed/Nwdye5_I73s',
    galleryCount: 4,
    id: 1,
  },
]
module.exports = featuredProjectData
