import React from 'react'
import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'

interface IProps {
  children: React.ReactNode
  className?: string
}
/*
const Container = (props: IProps): React.ReactElement => {
  return <BreadcrumbBarContainer className={props.className}>{props.children}</BreadcrumbBarContainer>
}
*/
const Container = styled.div`
  padding: 1rem;
  background: white;
  display: flex;
  margin: 0 auto 0;
  width: 100%;
`
const List = styled.ul`
  li {
    padding: 0 1.5rem 0 0;
  }
  a:hover {
    color: ${styleConfig.colors.blue[800]};
  }
`
const BreadcrumbBar = (props: IProps): React.ReactElement => {
  return <Container className={`mx-auto container justify-between ${props.className}`}>{props.children}</Container>
}
const Content = (props: IProps): React.ReactElement => {
  return <List className={`lg:text-xl overflow-hidden flex ${props.className}`}>{props.children}</List>
}
BreadcrumbBar.Content = Content
export default BreadcrumbBar
