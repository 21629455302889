import { AppContext, lightBoxVisibleAction } from 'state/context/appContext'

import BreadcrumbBar from 'components/BreadcrumbBar'
import FeatureGrid from 'components/featureGrid'
import HaveProject from 'components/haveProject'
import Masthead from 'components/mastHead'
import PageContent from 'components/pageContent'
import React from 'react'
import { SiteIcons } from 'components/icons/SiteIcon'
import featuredProjectData from 'models/featuredProjectData'
import { galleryData } from 'models/galleryData'
import img from './masthead.jpg'
import styled from '@emotion/styled'

const galleryImageWidth = '278px'
const GalleryThumb = styled.div`
  overflow: hidden;
  position: relative;
  margin: 1rem;
  display: block;
  border-radius: 7px;
  border-color: #ccc;
  border-width: thin;
  border-style: solid;
  .gallery-item-label {
    position: absolute;
    z-index: 9;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    transition: all 0.2s ease;
    border-radius: 7px;
    display: flex;
  }
  img {
    transition: all 0.5s ease;
    width: 278px;
    height: 160px;
  }
  &:hover {
    .gallery-item-overlay {
      opacity: 0.8;
    }
    img {
      transform: scale(1.1);
    }
    .gallery-item-label {
      margin-top: 0;
      opacity: 1;
    }
  }
  .gallery-item-overlay {
    z-index: 8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1784fb;
    opacity: 0;
    transition: all 0.5s ease;
    border-radius: 7px;
  }
`
const BlankBox = styled.div`
  width: ${galleryImageWidth};
  margin: 1rem;
  height: 0;
`

const PortfolioPage = (): React.ReactElement => {
  const { dispatch } = React.useContext(AppContext)
  const handleOpenGallery = (e: React.MouseEvent | React.KeyboardEvent): void => {
    const photoId = Number(e.currentTarget.getAttribute('data-photo-id'))
    const Images = galleryData.images.map((item) => `${galleryData.photoPath}/${item.name}.jpg`)
    dispatch(lightBoxVisibleAction(true, Images, photoId))
  }
  // eslint-disable jsx-a11y/no-static-element-interactions
  return (
    <>
      <Masthead backgroundImage={img}>
        <h1 className="lg:text-6xl text-5xl text-center">Portfolio</h1>
      </Masthead>
      <BreadcrumbBar>
        <BreadcrumbBar.Content>
          <li>
            <a href="#featuredwork">Featured Work</a>
          </li>
          <li>
            <a href="#gallery">Gallery</a>
          </li>
        </BreadcrumbBar.Content>
      </BreadcrumbBar>
      <div className="bg-gray-200 flex flex-col flex-grow">
        <PageContent id="featuredwork" className="mb-20 my-3 p-4 items-center">
          <h2 className="font-thin text-center lg:text-5xl ">Featured Work</h2>
          <p className="text-2xl font-thin">Take a look at some of the projects we&apos;ve worked on.</p>
          <FeatureGrid data={featuredProjectData} />
        </PageContent>
      </div>
      <div className="bg-white flex flex-col flex-grow">
        <PageContent id="gallery" className="lg:mb-20 p-4 items-center">
          <h2 className="font-thin text-center lg:text-5xl lg:mt-10 pt-6">Gallery</h2>
          <p className="font-thin  lg:text-2xl">Our work gallery quickly showcases some of the work we&apos;ve done over the years</p>
          <div className="flex flex-wrap flex-row w-full justify-around">
            {galleryData.images.map((galleryitem, mapID): React.ReactNode | void => {
              return (
                <div role="button" tabIndex={0} key={mapID} data-photo-id={mapID} onKeyDown={handleOpenGallery} onClick={handleOpenGallery} className="animated fadeIn">
                  <GalleryThumb className="rounded-lg shadow-md">
                    <div className="gallery-item-label">
                      <SiteIcons.ZoomIn size="40" color="white" className="rollover-icon" />
                    </div>
                    <div className="gallery-item-overlay"></div>
                    <img alt="" className="img-responsive" src={`${galleryData.photoPath}/${galleryitem.name}_thumb.jpg`} />
                  </GalleryThumb>
                </div>
              )
            })}
            <BlankBox />
            <BlankBox />
            <BlankBox />
            <BlankBox />
          </div>
        </PageContent>
      </div>
      <HaveProject />
    </>
  )
}

export default PortfolioPage
