import { Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'

const maxWidth = '380px'
const FeaturedCard = styled.div`
  overflow: hidden;
  border-color: #ccc;
  border-width: thin;
  border-style: solid;
  flex-basis: ${maxWidth};
  max-width: ${maxWidth};
  margin: 1rem;
  background: white;
  .imgContainer {
    border-bottom: #ccc thin solid;
    overflow: hidden;
    width: 378px;
    height: 252px;
  }
  img {
    transition: all 0.5s ease;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`
const BlankFeature = styled.div`
  flex-basis: ${maxWidth};
  height: 0;
`
interface IProps {
  className?: string
  data: IFeaturedProject[]
}

const FeatureGrid = (props: IProps): React.ReactElement => {
  return (
    <div className="flex flex-wrap flex-row w-full justify-around lg:py-5">
      {props.data.map((project, mapID): React.ReactNode | void => (
        <FeaturedCard key={mapID} className="rounded-lg shadow-md " data-aos="fade-down">
          <Link to={`/portfolio/project/${project.key}`}>
            <div className="imgContainer">
              <img className="img-responsive animated fadeIn" src={`/img/portfolio/featured-projects/${project.key}/featured_thumb.jpg`} alt="" />
            </div>
            <div className="px-4 pt-4">
              <h5 className="mb-1">{project.title}</h5>
              <p className="text-base mb-2" dangerouslySetInnerHTML={{ __html: project.description }} />
            </div>
            <i className="pr-3 pb-3 float-right text-gray-400 project-item-arrow fa fa-arrow-circle-right " aria-hidden="true"></i>
          </Link>
        </FeaturedCard>
      ))}
      <BlankFeature />
      <BlankFeature />
      <BlankFeature />
    </div>
  )
}

export default FeatureGrid
