export interface IGalleryImage {
  name: string
  description: string
}
export interface IGalleryData {
  photoPath: string
  images: IGalleryImage[]
}

export const galleryData: IGalleryData = {
  photoPath: '/img/portfolio/gallery',
  images: [
    { name: 'smart', description: '' },
    { name: 'produceplace', description: '' },
    { name: 'innocean', description: '' },
    { name: 'moneris', description: '' },
    { name: 'amex', description: '' },
    { name: 'ofg1', description: '' },
    { name: 'ofg2', description: '' },
    { name: 'ofgadmin', description: '' },
    { name: 'ofgsupply', description: '' },
    { name: 'optimizer', description: '' },
    { name: '2', description: '' },
    { name: '4', description: '' },
    { name: '5', description: '' },
    { name: 'ashleymadison', description: '' },
    { name: 'bellworld', description: '' },
    { name: 'brandsinmotion', description: '' },
    { name: 'cfhi', description: '' },
    { name: 'clothesline2', description: '' },
    { name: 'crossword', description: '' },
    { name: 'cts', description: '' },
    { name: 'envy', description: '' },
    { name: 'harlequin', description: '' },
    { name: 'matrixsite1', description: '' },

    { name: 'poledance1', description: '' },

    { name: 'resourcemedia', description: '' },
    { name: 'vpg', description: '' },
    { name: 'zwak', description: '' },
  ],
}
