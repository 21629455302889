import Head from 'components/head'
import Layout from 'layouts'
import PortfolioPage from 'components/pages/portfolioPage'
import React from 'react'
import { metaTagData } from 'config'

const Portfolio = (): React.ReactElement => {
  return (
    <>
      <Head metaTagData={metaTagData} />
      <Layout>
        <PortfolioPage />
      </Layout>
    </>
  )
}

export default Portfolio
